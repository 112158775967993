///include /assets/js/plugins/notify.js

/**
 * We have used unique addStyle calls here because the icon is different in each
 * of the HTML snippets..
 */

$.notify.addStyle("success", {
    html: `<div>\n<i class="far fa-check-circle"></i><span data-notify-html class="ml-2"></span>\n</div>`,
    classes: {
        green: {
            "font-size": "14px",
            "font-weight": "700",
            "padding": "20px",
            "border-radius": "8px",
            "white-space": "nowrap",
            "background-repeat": "no-repeat",
            "background-position": "3px 7px",
            "background-color": "#E1EFDB",
            "color": "#004D17",
            "max-width": "450px",

        },
    },
})

$.notify.addStyle("warning", {
    html: `<div>\n<i class="far fa-exclamation-circle"></i><span data-notify-html class="ml-2"></span>\n</div>`,
    classes: {
        warning: {
            "font-size": "14px",
            "font-weight": "700",
            "padding": "20px",
            "border-radius": "8px",
            "white-space": "nowrap",
            "background-repeat": "no-repeat",
            "background-position": "3px 7px",
            "background-color": "#FFE9C1",
            "color": "#792809",
            "max-width": "450px",
        },
    },
})

$.notify.addStyle("fail", {
    html: `<div>\n<i class="far fa-times-circle"></i><span data-notify-html class="ml-2"></span>\n</div>`,
    classes: {
        fail: {
            "font-size": "14px",
            "font-weight": "700",
            "padding": "20px",
            "border-radius": "8px",
            "white-space": "nowrap",
            "background-repeat": "no-repeat",
            "background-position": "3px 7px",
            "background-color": "#FFF5F6",
            "color": "#A20C1B",
            "max-width": "450px",
        },
    },
})

// This is the same as fail above except it takes HTML. ONLY to be called
// through an HTML-escaping wrapper.
$.notify.addStyle("fail-compiled", {
    html: `<div>\n<i class="far fa-times-circle"></i><span data-notify-html class="ml-2" style="color:#A20C1B;"></span>\n</div>`,
    classes: {
        fail: {
            "font-size": "14px",
            "font-weight": "700",
            "padding": "20px",
            "border-radius": "8px",
            "white-space": "nowrap",
            "background-repeat": "no-repeat",
            "background-position": "3px 7px",
            "background-color": "#FFF5F6",
            "color": "#A20C1B",
            "max-width": "450px",
        },
    },
})

class ShowNotification {
    /**
     * Shows a success message
     * @param {string} message
     * @param {{close?: boolean, additionalText?: string} | null} [options]
     */
    static success(message, options = null) {
        /** @type {{style: string, className: string, autoHide?: boolean}} */
        const ob = {
            style: "success",
            className: "green",
        }

        if(options && options.close) {
            ob.autoHide = false
        }

        let message_compiled = $("<p>").append(
            $("<span>").attr("class", "text-wrap").css({"color":"#004D17"}).text(message || "")
        )

        if(options?.additionalText) {
            message_compiled = message_compiled.append(
                $("<div>")
                    .attr("class", "mt-2 text-wrap")
                    .css({
                        "font-weight": 400,
                        "word-wrap": "break-word",
                        "color": "#004D17"
                    })
                    .text(options.additionalText || "")
            )
        }

        message_compiled = message_compiled.html()

        // Notifyjs bug: if the first arg is undefined, it crashes.
        $.notify(message_compiled, ob)
    }

    /**
     * Shows a warning
     * @param {string} message
     * @param {{close?: boolean, additionalText?: string} | null} [options]
     */
    static warn(message, options = null) {
        /** @type {{style: string, className: string, autoHide?: boolean}} */
        const ob = {
            style: "warning",
            className: "warning",
        }

        if(options && options.close) {
            ob.autoHide = false
        }

        let message_compiled = $("<p>").append(
            $("<span>").attr("class", "text-wrap").css({"color": "#792809"}).text(message || "")
        )

        if(options?.additionalText) {
            message_compiled = message_compiled.append(
                $("<div>")
                    .attr("class", "mt-2 text-wrap")
                    .css({
                        "font-weight": 400,
                        "word-wrap": "break-word",
                        "color": "#792809"
                    })
                    .text(options.additionalText || "")
            )
        }

        message_compiled = message_compiled.html()

        // Notifyjs bug: if the first arg is undefined, it crashes.
        $.notify(message_compiled || "", ob)
    }

    /**
     * Shows an error message
     * @param {string} message
     * @param {{close?: boolean, href?: string, additionalText?: string} | null} [options]
     */
    static fail(message, options = null) {
        /** @type {{style: string, className: string, autoHide?: boolean}} */
        const ob = {
            style: "fail",
            className: "fail",
        }

        if(options && options.close) {
            ob.autoHide = false
        }

        let message_compiled = $("<p>").append(
            $("<span>").attr("class", "text-wrap").css({"color": "#A20C1B"}).text(message || "")
        )

        if(options && options.href) {
            message_compiled = $("<p>").append(
                $("<a>")
                    .attr("href", options.href)
                    .css("color", "inherit")
                    .text(message || "")
            )
        }

        if(options?.additionalText) {
            message_compiled = message_compiled.append(
                $("<div>")
                    .attr("class", "mt-2 text-wrap")
                    .css({
                        "font-weight": 400,
                        "word-wrap": "break-word",
                        "color": "#A20C1B"
                    })
                    .text(options.additionalText || "")
            )
        }

        message_compiled = message_compiled.html()

        // Notifyjs bug: if the first arg is undefined, it crashes.
        $.notify(message_compiled, ob)
    }
}
